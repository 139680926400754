import { __decorate } from "tslib";
import { Component, Vue, Ref, Emit, } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import moment from 'moment';
import { LogService } from '@/sevices';
let FilterAppManageLog = class FilterAppManageLog extends Vue {
    constructor() {
        super(...arguments);
        this.rangeDate = [];
        this.appList = [];
        this.form = {
            beginTime: '',
            endTime: '',
            ip: '',
            appCode: undefined,
            userName: '',
        };
        this.rules = [];
    }
    onChange(date) {
        let beginMomentTime = '';
        let endMomentTime = '';
        if (date.length) {
            const beginTime = date[0] || {};
            const endTime = date[1] || {};
            beginMomentTime = moment(beginTime).format('YYYY-MM-DD HH:mm:ss');
            endMomentTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss');
        }
        this.form.beginTime = beginMomentTime;
        this.form.endTime = endMomentTime;
    }
    reset() {
        this.form.beginTime = '';
        this.form.endTime = '';
        this.form.ip = '';
        this.form.appCode = undefined;
        this.form.userName = '';
        this.rangeDate = [];
    }
    async getLogAppList() {
        const res = await LogService.getLogAppList();
        if (res.success) {
            this.appList = res.data || [];
        }
    }
    created() {
        this.getLogAppList();
    }
    search() {
        return this.form;
    }
};
__decorate([
    Ref()
], FilterAppManageLog.prototype, "cform", void 0);
__decorate([
    Emit('search')
], FilterAppManageLog.prototype, "search", null);
FilterAppManageLog = __decorate([
    Component({
        name: 'FilterAppManageLog',
        components: {
            CForm: Form,
            CFormItem: FormItem,
        },
    })
], FilterAppManageLog);
export default FilterAppManageLog;
